import "@fontsource/archivo";
let projects = [
  {
    projectName: "1 - Impact Tinder",
    tagline: "Web portal for Transformers volunteers to connect with each other and generate impact around social causes",
    description:
      "Our experience as Movimento Transformers volunteers has changed our way of seeing and understanding the world and has impacted, in previous years, career changes (or beginnings) to the Social Entrepreneurship field. If this happens organically, imagine if we actively pursued it! Our proposal is to make use of the online Transformers community with over 450 members, start with a major list of skills, experience and social causes of each member, and create a landing page with all that information. From there, each person interested in learning more about a given field could send a message to someone else (or create a buddy group) and suggest dates with Impact.",
    ethereumAddress: "0x989b540aC3406B7Aa3A4985B32f15dc5BDFB4e7b",
    website: "A experiência como mentores do Movimento Transformers tem mudado a nossa forma de perceber o mundo e tem tido impacto, em gerações anteriores, na transição de carreira (ou início de carreira) para a área do empreendedorismo social. Se isto acontece informalmente imaginem se o colocássemos na agenda! A nossa proposta é aproveitar a comunidade online dos Transformers onde estão mais do que 400 pessoas e fazer, em primeiro lugar, um levantamento das skills, experiência e causas dos membros, criando uma landing page com todas essas informações. A partir daí cada pessoa com interesse em aprender mais sobre determinada área poderia entrar em contacto com uma dessas pessoas (ou várias, criando grupos de buddies) e sugerir dates de impacto.",
    thumbnailImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995346/1_idea_uodlqu.png",
    logoCdnUrl:
      "https://www.movimentotransformers.org/assets/images/logo-animated.gif",
    bannerImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995633/portobanner5_q56ooz.jpg",
    recipientId: 1,
    id: "1",
  },

  {
    projectName: "2 - Gender Equality in Sports",
    tagline:
      "In-person summit on Gender Equality in Sports for local sencondary school, with the participation of several female athletes, round table discussions and Q&amp;A moments. The closing of the event would be a mix-class and all-gender tournament.",
    description:
      "The class (7th grade), along with their two volunteer mentor, identified a problem in their school community - girls and boys are treated differently in school sports, in class, as well as in the playground. Since many girls in class like sports, and want to promote an environment of equal participation, they want to implement two ideas: - An in-person summit on the topic, with the participation of several female athletes, round table discussions and Q&amp;A moments. The closing of the event would be a mix-class and all-gender tournament. - Digital activism - to create an online presence, using social media, to bring awareness and sensitization on the issue, in a creative way adapted to younger generations and school community.",
    ethereumAddress: "0x989b540aC3406B7Aa3A4985B32f15dc5BDFB4e7b",
    website: "Sentimos que na nossa comunidade escolar há uma diferença de tratamento de rapazes e raparigas em relação a desportos coletivos, seja nos intervalos ou até durante as aulas. Todos e todas nós gostamos de desporto, e queremos promover um ambiente de participação igual. Temos duas ideias que queremos desenvolver: - Conferência presencial sobre o tema, organizado na escola, com atletas convidadas e momentos de mesa redonda e entrevista. O encerramento seria um torneio inter-turmas misto. - Ativismo digital - criar uma plataforma online, através das redes sociais, para consciencializar e sensibilizar para o tema, de uma forma criativa e adaptada às gerações mais novas e à comunidade escolar.",
    thumbnailImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995346/2_ideia_uza8bb.png",
    logoCdnUrl:
      "https://www.movimentotransformers.org/assets/images/logo-animated.gif",
    bannerImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995633/portobanner5_q56ooz.jpg",
    recipientId: 2,
    id: "2",
  },
  {
    projectName: "3 - Transforming Youth Participation",
    tagline:
      "Bootcamp to capacitate and empower young people for an active participation in society",
    description:
      "We identified a problem - young people participate very little. They attend few protests, do not sign petitions, volunteer very little, and are often not interested in voting. They feel that no one listens to them or takes them seriously, and that the world of civic participation is complicated. We want to solve this issue. Our group wants to study the participation of young people throughout the country, using online forms. After this, we want to develop a Bootcamp and travel the country with it, to capacitate and empower young people for an active participation in society",
    ethereumAddress: "0x989b540aC3406B7Aa3A4985B32f15dc5BDFB4e7b",
    website: "Identificamos um problema - os jovens participam pouco. Não vão a manifestações, não assinam petições, não fazem voluntariado, muitas vezes não têm interesse em votar. Sentem que ninguém os ouve ou leva a sério, e que o mundo da participação é complicado. Queremos resolver este problema. O nosso grupo quer fazer uma auscultação nacional da participação jovem através de inquéritos online, e desenvolver um mega bootcamp itinerante que vai percorrer o país e capacitar e empoderar os jovens para participarem ativamente na sociedade.",
    thumbnailImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995346/3_idea_fypoui.png",
    logoCdnUrl:
      "https://www.movimentotransformers.org/assets/images/logo-animated.gif",
    bannerImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995633/portobanner2_zryfmp.jpg",
    recipientId: 3,
    id: "3",
  },
  {
    projectName: "4 - Toddlers Trip - From IDGs to SDGs",
    tagline:
      "Work with children of today that are going to be the leaders of tomorrow: develop a program to teach young leaders skills to address Sustainable Development Goals (SDGs)",
    description:
      "In 2015, the Sustainable Development Goals gave us a comprehensive plan for a sustainable world by 2030. The 17 goals cover a wide range of issues that involve people with different needs, values, and convictions. There is a vision of what needs to happen, but progress along this vision has so far been disappointing. We lack the inner capacity to deal with our increasingly complex environment and challenges. Fortunately, modern research shows that the inner abilities we now all need can be developed and the &#39;Inner Development Goals&#39; initiative identified 5 dimensions and 23 skills and qualities which are especially crucial for leaders who address SDGs, but fundamentally for all of us. This is our payback idea - to work with children of today that are going to be the leaders of tomorrow: develop a program to work on these 23 skills from early on. The main objective of this programme is not just develop these skills with the group of toddlers but also co-create with them an interactive book to be used with the next group and so on.",
    ethereumAddress: "0x989b540aC3406B7Aa3A4985B32f15dc5BDFB4e7b",
    website: "Em 2015, os Objetivos para o Desenvolvimento Sustentável (ODS) deram-nos um extenso plano de ação por um mundo mais sustentável até 2030. Os 17 objetivos incluem uma grande variedade de temas que envolvem pessoas com diferentes necessidades, valores, e convicções. Há uma visão do que tem de acontecer, mas o progresso tem sido desapontante. Falta-nos a capacidade interna de lidar com os desafios cada vez mais complexos que nos surgem. Felizmente, estudos modernos mostram que as skills internas de que precisamos podem ser desenvolvidas e trabalhadas e os Objetivos para o Desenvolvimento Interior (ODI) identificam 5 dimensões e 23 capacidades ou qualidades que são especialmente importantes e essenciais. Esta é a nossa ideia de payback - trabalhar com as crianças de hoje para que sejam os líderes de amanhã, através de um programa que permita trabalhar estas 23 skills desde cedo. O maior objetivo é não só desenvolver estas skills com um grupo de crianças, mas também criar com o grupo um livro interativo que possa ser usado em iniciativas futuras.",
    thumbnailImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995346/4_idea_lpe56y.png",
    logoCdnUrl: "https://www.movimentotransformers.org/assets/images/logo-animated.gif",
    bannerImageLink: "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995633/portobanner5_q56ooz.jpg",
    recipientId: 4,
    id: "4",
  },
  {
    projectName: "5 - One week to… discover your talent!",
    tagline:
      "Creative Residence (with arts, sports, STEM, and  personal development workshops for the Transformers community to explore what talents, dreams and passions they have",
    description:
      "Many of us don’t know who we want to be or what we want to do in the future - and many people have told us that our options are limited. In Transformers, talents are shared to transform communities - we want to the same for eachother. Out idea is to implement a Creative Residence (with arts, sports, STEM, and much more) which will be an immersive experience, allowing young people to try many things, have personal development workshops and non-formal education moments, and learn what talents, dreams and passions they have.",
    ethereumAddress: "0x989b540aC3406B7Aa3A4985B32f15dc5BDFB4e7b",
    website: "Muitos de nós não sabem o que querem ser ou fazer no futuro - e já demasiadas pessoas nos disseram que as opções são limitadas. Nos Transformers, os talentos são partilhados para transformar a comunidade - queremos fazer o mesmo uns pelos outros. A nossa ideia é uma residência criativa (artística, desportiva, de STEM, e não só) que vai ser uma experiência imersiva, permitindo aos jovens experimentar muita coisa, ter workshops de desenvolvimento pessoal e educação não formal, e perceber que talentos, sonhos e paixões têm!",
    thumbnailImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995346/5_idea_ruvcps.png",
    logoCdnUrl:
      "https://www.movimentotransformers.org/assets/images/logo-animated.gif",
    bannerImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995633/portobanner5_q56ooz.jpg",
    recipientId: 5,
    id: "5",
  },
  {
    projectName: "6 - To be a Volunteer - an interactive manual",
    tagline:
      "Interactive volunteering manual that will allow anyone and everyone to grow as a volunteer, an activist, and an overall active participant.",
    description:
      "Being a mentor with Movimento Transformers taught me how to be a volunteer, and how to use my talents to impact a class of young apprentices. After some time of doing this, I feel that our methodology and day-to-day experience could be very useful to people that want to volunteer and participate more in their communities. I want to, in collaboration with other Transformers volunteers, develop an interactive volunteering manual which will allow anyone and everyone to grow as a volunteer, an activist, and an overall active participant.",
    ethereumAddress: "0x989b540aC3406B7Aa3A4985B32f15dc5BDFB4e7b",
    website:
      "Ser Mentora do Movimento Transformers ensinou-me a ser voluntária, e a usar os meus talentos para impactar uma turma de jovens aprendizes. Depois de algum tempo a fazer isto, sinto que a metodologia e a nossa experiência de terreno no dia a dia poderia ser muito útil para muitas pessoas que querem fazer voluntariado e ser mais ativas e transformadoras nas suas comunidades. Quero, em conjunto com outros mentores e mentoras voluntários/as, desenvolver um manual de voluntariado interativo, que permita crescimento de cada pessoa enquanto voluntária, ativista, e participante ativa na sua comunidade.",
    thumbnailImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995346/6_idea_cyj8du.png",
    logoCdnUrl:
      "https://www.movimentotransformers.org/assets/images/logo-animated.gif",
    bannerImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995633/portobanner2_zryfmp.jpg",
    recipientId: 6,
    id: "6",
  },
  {
    projectName: "7 - Saving the world… with CREATIVITY",
    tagline:
      "Fashion show totally produced by the Transformers community, to empahsize sustainability and creativity in Porto.",
    description:
      "We really love fashion, and our mentors tell us we are much more creative than we think. So, we came up with this idea - to workout creativity mixing fashion, that we love,with sustainability, something we all need more of. We want to put on a fashion show totally produced by us, with used clothes, fabric no one wants, recycled materials, and many other things. We want to host our own Fashion Week, but filled with transformation and community!",
    ethereumAddress: "0x989b540aC3406B7Aa3A4985B32f15dc5BDFB4e7b",
    website: "Gostamos muito de roupa, e as nossas mentoras dizem-nos que somos mais criativas do que sabemos. Então, tivemos uma ideia em conjunto - trabalhar a nossa criatividade misturando moda, que adoramos, com sustentabilidade, algo que todos precisamos. Queremos fazer um desfile de moda totalmente produzido por nós, a partir de roupas usadas, excedentes de tecido, materiais reciclados, entre outras coisas. Vamos fazer a nossa própria Semana da Moda, mas com transformação e comunidade!",
    thumbnailImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995347/7_idea_pbtc72.png",
    logoCdnUrl:
      "https://www.movimentotransformers.org/assets/images/logo-animated.gif",
    bannerImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995633/portobanner1_ndcn0s.jpg",
    recipientId: 7,
    id: "7",
  },
  {
    projectName: "8 - We are… A Play About Us",
    tagline:
      "A theater play about, written, and produced by “youth at risk of social exclusion” in  What we feel, dream, think, and want to do. What makes us angry,",
    description:
      "They tell us we are “youth at risk of social exclusion”. Many words to say we are invisible and often ignored by a large slice of society. Everyday we feel that we accumulate and lock all our feelings and thoughts inside, with no way to express them, especially because no one seems to care. We are learning the talents of theater and hip-hop, and want to use them as a way to say everything we normally can’t - we will write and produce a theater play about US. What we feel, dream, think, and want to do. What makes us angry, but also happy - EVERYTHING. And we will do everything, as well- write the screenplay, make the scenery and clothing, all of it. We want to present this to our community so they finally listen to us, and stop ignoring us.",
    ethereumAddress: "0x989b540aC3406B7Aa3A4985B32f15dc5BDFB4e7b",
    website: "Dizem que somos “jovens em risco de exclusão social”. Palavras a mais para dizer que somos invisíveis e ignorados por grande parte da sociedade. Cada vez mais sentimos que acumulamos tudo o que pensamos e sentimos cá dentro, sem forma de nos expressar, até porque ninguém quer ouvir. Estamos a aprender os talentos do teatro e do hip-hop, e queremos usá-los como forma de dizer tudo aquilo que normalmente não conseguimos. Vamos escrever e produzir uma peça de teatro sobre NÓS.O que sentimos, sonhamos, pensamos, queremos fazer. O que nos revolta mas também o que nos faz felizes - TUDO. E vamos ser nós a fazer tudo, também. Escrever o texto, ensaiar, fazer o cenário, a roupa. Queremos apresentar isto na nossa comunidade para que nos ouçam, e deixem de nos ignorar.",
    thumbnailImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995347/8_idea_ptl8hi.png",
    logoCdnUrl:
      "https://www.movimentotransformers.org/assets/images/logo-animated.gif",
    bannerImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995633/portobanner1_ndcn0s.jpg",
    recipientId: 8,
    id: "8",
  },
  {
    projectName: "9 - Transforming our Public Spaces",
    tagline:
      "Rebuilding abandoned and destroyed football and futsal fields, paint dilapidated walls, rethink and rebuild abandoned gardens",
    description:
      "We feel that our neighborhood does not represent us, and the potential of several public spaces is absolutely wasted. Our streets deserve renovation and transformation, to represent us better. This is what we want to do - for our community to be the public space that reflects how we feel and who we are. With due permission, we want to rebuild abandoned and destroyed football and futsal fields, paint old walls that are badly painted, rethink and rebuild abandoned gardens, among many other spaces. We feel that the sadness of our neighborhoods affects how we feel, and we want to change this, so that the world around us can bring us more happiness and color.",
    ethereumAddress: "0x989b540aC3406B7Aa3A4985B32f15dc5BDFB4e7b",
    website: "Sentimos que o nosso bairro não nos representa - e que muitos espaços estão sub-aproveitados e merecem ser renovados e transformados. É isto que queremos fazer: que as nossas ruas e o espaço público reflita a forma como nos sentimos. Com a devida autorização, queremos remodelar campos de futebol e futsal velhos e inutilizados, pintar paredes velhas e/ou mal pintadas, repensar e refazer jardins abandonados, entre muitos outros espaços. Achamos que a tristeza das nossas cidades afeta a forma como nos sentimos, e queremos mudar isto - que o mundo à nossa volta nos traga mais alegria e cor.",
    thumbnailImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995347/9_idea_dojkul.png",
    logoCdnUrl:
      "https://www.movimentotransformers.org/assets/images/logo-animated.gif",
    bannerImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995633/portobanner1_ndcn0s.jpg",
    recipientId: 9,
    id: "9",
  },
  {
    projectName: "10 - A Community Vegetable Garden to Include Everyone",
    tagline:
      "A community vegetable and fruit garden in one of the Center for Migrant and Refugee People and Families in our city.",
    description:
      "In the last months we have developed and built a community vegetable and fruit garden in one of the Center for Migrant and Refugee People and Families in our city. Our group is mostly made up of young refugees and migrants, but also several families, children, adults, and even elders. They come from many different countries and backgrounds. The process of developing this garden has been very interesting and gratifying, but we want to make this truly for the entire community - we are thinking of a way to scale up the idea, in a public place, so we can integrate many different people and communities, and have a true sharing space for everyone that wants to participate. We don’t speak the same language, but the ground is the same everywhere - this is what moves us weekly, and we really want to contaminate the rest of the community with this idea and feeling.",
    ethereumAddress: "0x989b540aC3406B7Aa3A4985B32f15dc5BDFB4e7b",
    website: "Nos últimos meses temos desenvolvido uma horta comunitária num dos Centros de Acolhimento e Integração de Famílias e Pessoas Refugiadas da nossa cidade. O nosso grupo de aprendizes são muitos jovens migrantes e refugiados, mas não só. O processo de desenvolvimento da horta tem sido muito gratificante, mas queremos muito que esta seja verdadeiramente para toda a comunidade - pensamos numa forma de fazer isto em grande escala, num local público, de forma a podermos integrar muitas outras pessoas e comunidades, e termos um verdadeiro espaço de partilha para todas e quaisquer pessoas que tenham interesse em comunicar. Não falamos a mesma língua, mas a terra é igual em todo o lado - é isto que nos move semanalmente, e queremos muito contagiar o resto da comunidade com esta ideia.",
    thumbnailImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995348/10_idea_v4mhow.png",
    logoCdnUrl:
      "https://www.movimentotransformers.org/assets/images/logo-animated.gif",
    bannerImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995633/portobanner2_zryfmp.jpg",
    recipientId: 10,
    id: "10",
  },
  {
    projectName: "11 - Transformation through Animation",
    tagline:
      "Creating animated short movies about things like racism, bullying, domestic violence, different treatment of men and women, poverty,",
    description:
      "We have two major goals - we want to learn video animation and how to create short animated movies (one of the talents our mentors share with us), and also bring awareness to many issues we feel every day. Things like racism, bullying, domestic violence, different treatment of men and women, poverty, among many others. We want to learn how to work this animation software, write several scripts, and create many animated short movies on the topic, so they can be posted in social media but also presented in schools and foster care centers like ours, so these topics are more talked about and better understood.",
    ethereumAddress: "0x989b540aC3406B7Aa3A4985B32f15dc5BDFB4e7b",
    website: "Com esta ideia temos dois objetivos - queremos aprender a animar vídeos e desenvolver curtas metragens (um dos talentos que os nossos mentores partilham connosco), e consciencializar para muitos problemas que sentimos todos os dias. Enfrentamos muitas vezes situações de racismo, bullying, violência doméstica, tratamento diferente de homens e mulheres, pobreza, entre muitos outros. Queremos aprender a trabalhar com o software de animação, escrever vários guiões e produzir muitas curtas-metragens animadas sobre estes temas, para serem divulgadas nas redes sociais, mas também apresentadas em escolas e em centros de acolhimento como os nosso, para que se saiba e fale mais sobre os temas.",
    thumbnailImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995347/11_idea_b2ggcp.png",
    logoCdnUrl:
      "https://www.movimentotransformers.org/assets/images/logo-animated.gif",
    bannerImageLink:
      "https://res.cloudinary.com/pse-qf-maci/image/upload/v1678995633/portobanner1_ndcn0s.jpg",
    recipientId: 11,
    id: "11",
  }
];

export function getProjects() {
  return projects;
}

export function getRecipientIdbyId(id) {
  console.log(id);
  const p = projects.find((p) => p.id === id);

  return p.recipientId;
}

export function getProject(id: string) {
  return (
    projects.find((p) => p.id === id) ?? {
      projectName: "404 Project not found",
      tagline: "404 Tagline not found",
      description: "404 Description not found",
      ethereumAddress: "404 Address not found",
      website: "404 URL not found",
      thumbnailImageLink: "https://source.unsplash.com/random",
      logoCdnUrl: "https://source.unsplash.com/random",
      bannerImageLink: "https://source.unsplash.com/random",
      recipientId: 0,
      id: "0",
    }
  );
}

function shuffleFisherYates(array: any) {
  let i = array.length;
  while (i--) {
    const ri = Math.floor(Math.random() * i);
    [array[i], array[ri]] = [array[ri], array[i]];
  }
  return array;
}

//return shuffled version of allprojects array
export function getShuffledProjects() {
  return shuffleFisherYates(getProjects());
}
